import Api from "@/utils/api.js";
import moment from "moment";

export function debounce (func, wait, immediate) {
    var timeout;
    return function() {
        var context = this; 
        var args = arguments;
        var later = function() {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}

export function get_class_name (cls) {
    return cls.constructor.name.toLowerCase();
}

export function format_field_name (name) {
    name = name.toLowerCase()
               .split(/_{1,2}/g);

    for (let i = 0; i < name.length; i++) {
        name[i] = name[i].charAt(0).toUpperCase() + name[i].substring(1)
    }

    return name.join(" ");
}

export function format_query (query) {
    if (query === undefined) {
        return [];
    }

    var formatted_query = [];
    for (var i = 0; i < query.length; ++i) {
        if (typeof(query[i]) === 'string') {
            var x = {};
            x[query[i]] = []; 
            formatted_query.push(x);
        } else {
            formatted_query.push(query[i]);
        }
    }

    return formatted_query;
}

export function merge_queries (q1, q2) {
    let query_1 = format_query(q1);
    let query_2 = format_query(q2);

    var query = query_1.concat(query_2);
    var output = [];

    for (var i = 0; i < query.length; ++i) {
        var item = query[i];
        var key = Object.keys(item)[0];
        var value = item[key];
        var merged_value = value;

        for (var j = 0; j < output.length; ++j) {
            var item_output = output[j];
            var key_output = Object.keys(item_output)[0];
            var value_output = item_output[key_output];

            if (key === key_output) {
                merged_value = merge_queries(value, value_output);
                output.splice(j, 1);
                break;
            }
        }

        var obj = {};
        obj[key] = merged_value;
        output.push(obj);
    }

    return output;
}

const CHARS = '0123456789ABCDEF'.split('');
const FORMAT = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
export function uuid4 () {
    let	 c = CHARS, id = FORMAT, r;

	id[0] = c[(r = Math.random() * 0x100000000) & 0xf];
	id[1] = c[(r >>>= 4) & 0xf];
	id[2] = c[(r >>>= 4) & 0xf];
	id[3] = c[(r >>>= 4) & 0xf];
	id[4] = c[(r >>>= 4) & 0xf];
	id[5] = c[(r >>>= 4) & 0xf];
	id[6] = c[(r >>>= 4) & 0xf];
	id[7] = c[(r >>>= 4) & 0xf];

	id[9] = c[(r = Math.random() * 0x100000000) & 0xf];
	id[10] = c[(r >>>= 4) & 0xf];
	id[11] = c[(r >>>= 4) & 0xf];
	id[12] = c[(r >>>= 4) & 0xf];
	id[15] = c[(r >>>= 4) & 0xf];
	id[16] = c[(r >>>= 4) & 0xf];
	id[17] = c[(r >>>= 4) & 0xf];

	id[19] = c[(r = Math.random() * 0x100000000) & 0x3 | 0x8];
	id[20] = c[(r >>>= 4) & 0xf];
	id[21] = c[(r >>>= 4) & 0xf];
	id[22] = c[(r >>>= 4) & 0xf];
	id[24] = c[(r >>>= 4) & 0xf];
	id[25] = c[(r >>>= 4) & 0xf];
	id[26] = c[(r >>>= 4) & 0xf];
	id[27] = c[(r >>>= 4) & 0xf];

	id[28] = c[(r = Math.random() * 0x100000000) & 0xf];
	id[29] = c[(r >>>= 4) & 0xf];
	id[30] = c[(r >>>= 4) & 0xf];
	id[31] = c[(r >>>= 4) & 0xf];
	id[32] = c[(r >>>= 4) & 0xf];
	id[33] = c[(r >>>= 4) & 0xf];
	id[34] = c[(r >>>= 4) & 0xf];
	id[35] = c[(r >>>= 4) & 0xf];

	return id.join('');
}

export function isInViewport (el) {
    let bounding = el.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function check_types (val, types) {
    if (!Array.isArray(types)) {
        types = [types];
    }

    for (let type of types) {
        if (check_type(val, type)) {
            return true;
        }
    }

    return false;
}

export function check_type (val, type) {
    if (type.is_foreign_key || type.is_many_to_many) {
        return true;
    }

    if (type === Image) {
        try {
            window.atob(val);
            return true;
        } catch(e) {
            return false
        }
    }

    if (type === File) {
        return typeof val === "string";
    }

    if (val === null || val === undefined) {
        return true;
    }

    if (typeof type.check === "function") {
        return type.check(val);
    }

    if (type === Date && moment(val).isValid()) {
        return true;
    }

    if (val.constructor === type) {
        return true;
    }

    if (val instanceof type) {
        return true;
    }

    return false;
}

export function get_model (path) {
    let [app, model] = path.split(".");
    return import(`@/models/${app}/${model.toLowerCase()}.js`).then(module => {
        return module.default;
    });
}

export function get_cookie (name) {
	var cookie_value = null;
	if (document.cookie && document.cookie !== '') {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			while (cookie.charAt(0) == ' ') {
				cookie = cookie.substring(1);
			}
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookie_value = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookie_value;
}
