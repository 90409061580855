export default class EventEmitter {
    constructor () {
        this._listeners = {};
    }

    on (event_name, listener) {
        if (!(event_name in this._listeners)) {
            this._listeners[event_name] = [listener];
            return;
        }

        this._listeners[event_name].push(listener);
    }

    remove_all_event_listeners () {
        this._listeners = {};
    }

    remove_event_listeners (event_name) {
        this._listeners[event_name] = {};
    }

    fire (event_name, ...args) {
        if (!(event_name in this._listeners)) {
            return [];
        }

        let values = [];
        for (let listener of this._listeners[event_name]) {
            values.push(listener.call(this, ...args));
        }

        return values;
    }

    trigger () { return this.fire(...arguments); }
}
