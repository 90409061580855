import { createStore } from 'vuex';

export default createStore({
  state () {
      let _userChecked_resolve;
      let userChecked = new Promise ((resolve, reject) => {
        _userChecked_resolve = resolve;
      });
      return {
          userChecked,
          _userChecked_resolve,
          user: false
      };
  },
  mutations: {
    confirm_check_user (state) {
        state._userChecked_resolve();
    },
    connect (state, user) {
        state.user = user;
        state._userChecked_resolve();
    },
    disconnect (state) {
        state.user = false;
    }
  }
});
