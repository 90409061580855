import { reactive } from "vue";
import EventEmitter from "@/utils/event-emitter.js";

function makePromise () {
    let resolve, reject;

    let promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
}

export function group_by (list, group_field) {
    let groups = new Map();

    for (let el of list) {
        if (!el) {
            continue;
        }

        let idx = el[group_field];

        if (!idx) {
            continue;
        }

        if (!groups.has(idx)) {
            groups.set(idx, [el]);
            continue;
        }

        groups.get(idx).push(el);
    }

    return groups;
}

const STORES = {};

export default class Store extends EventEmitter {
    constructor ({
        index_key = "uuid"
    } = {}) {
        super();

        this.watched_indexes = {};
        this.index_key = index_key;
        this.elements = new Map();
    }

    find (fields, stop_on_first_hit = false) {
        let results = [];

        elements: for (let element of this.elements.values()) {
            check: for (let [filter_name, field_value] of Object.entries(fields)) {
                let contains_filter = filter_name.endsWith("__contains");
                let field_name = filter_name.replace("__contains", "");
                let element_value = element.get_value_by_name(field_name);

                if (Array.isArray(field_value) && Array.isArray(element_value)) {
                    let match = false;
                    for (let check_element of field_value) {
                        match |= element_value.includes(check_element);

                        if (match && contains_filter) {
                            continue check;
                        }
                    }

                    if (!match) {
                        continue elements;
                    }
                }

                if (element_value !== field_value) {
                    continue elements;
                }
            }

            results.push(element);

            if (stop_on_first_hit) {
                return results;
            }
        }

        return results;
    }

    find_by_id (id) {
        return this.elements.get(id);
    }

    get (fields) {
        return this.find(fields, true)[0];
    }

    exists (fields) {
        return this.find(fields, true).length > 0;
    }

    append (el, silent = false) {
        this.elements.set(el[this.index_key], reactive(el));

        if (!silent) {
            this.fire_append(el);
        }

        this.check_watchers();
    }

    fire_append (el) {
        this.fire("update", el);
        this.fire("append", el);
    }

    remove (el, silent = false) {
        let deleted = this.elements.delete(el[this.index_key]);

        if (deleted && !silent) {
            this.fire("update", el);
        }

        return deleted;
    }

    change_index (prev_index, el) {
        if  (el[this.index_key] === prev_index) {
            return;
        }

        this.elements.delete(prev_index);
        this.elements.set(el[this.index_key], el);
    }

    clear () {
        this.elements.clear();
        this.watched_indexes = {};
    }

    check_watchers () {
        let new_dict = {}; // Existing objects will be removed from watched

        for (let [index, promise] of Object.entries(this.watched_indexes)) {
            let obj = this.find_by_id(index);

            if (obj && obj.has_data) {
                promise.resolve(obj);
                continue;
            }

            new_dict[index] = promise;
        }

        this.watched_indexes = new_dict;
    }

    // Wait for an object to be added in this store
    watch (index) {
        let obj = this.find_by_id(index);
        
        if (obj && obj.has_data) {
            return Promise.resolve(obj);
        }

        if (!this.watched_indexes.hasOwnProperty(index)) {
            this.watched_indexes[index] = makePromise();
        }

        return this.watched_indexes[index];
    }

    static get_or_create (constructor) {
        const name = constructor.name;
        if (!STORES.hasOwnProperty(name)) {
            let s = new Store({
                index_key: constructor.index_field
            });
            STORES[name] = s;
            return s;
        }

        return STORES[name];
    }
}

window.s = STORES;
