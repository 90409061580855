import Notify from 'quasar/src/plugins/Notify.js';;
import { merge_queries, get_model, get_cookie } from "./utils.js";
import BaseModel from "@/models/base-model.js";
import EventEmitter from "@/utils/event-emitter.js";
import moment from "moment";

export class ApiError {
    constructor (message, code) {
        this.message = message;
        this.code = code;
    }

    toString () {
        return this.message;
    }
}

export class CrudFilter {
    constructor (field, operator, value, exclude = false) {
        if (value instanceof Date) {
            value = moment(value);
        }

        if (typeof value === "object" && value._isAMomentObject) {
            value = value.format("YYYY-MM-DD HH:mm:ssZZ");  // Make date Django-compatible
        }
        this.field = field;
        this.operator = operator;
        this.value = value;
        this.exclude = exclude;
    }
}

export default class Api {
    static is_model (data) {
        return (data.hasOwnProperty("pk") || data.hasOwnProperty("uuid")) && data.hasOwnProperty("_model_name");
    }

    static format_data (data) {
        if (!data) {
            return data;
        }

        if (Array.isArray(data)) {
            return data.map(o => Api.format_data(o));
        }

        if (typeof(data) === "string") {
            return data.trim();
        }

        if (data instanceof BaseModel) {
            return data[data.index_field];
        }

        if (typeof(data) === "object") {
            for (let [key, val] of Object.entries(data)) {
                data[key] = Api.format_data(val);
            }
            return data;
        }

        return data;
    }

    static async post ({
        action = null,
        handler = null,
        args = {},
        display_error = true,
        raw = false,
        json_only = false,
        accept_cached = true
    } = {}) {
        let promise;
        if (handler === null) {
            throw new Error("Api.post: 'handler' is not set");
        }

        args = Api.format_data(args);

        const csrftoken = get_cookie('csrftoken');
        let statusCode = 0;

        let debug_help = `api.${handler}`;

        if (handler === "api.crud") {
            debug_help = `crud.${args.action}:${args.model}`;
        }

        let res = await fetch(`/api/v2/?${debug_help}`, {
            method: "POST",
            mode: "same-origin",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken,
                "X-Accept-Cached": accept_cached
            },
            body: JSON.stringify({
                handler,
                args
            })
        });

        if (raw) {
            return res;
        }

        statusCode = res.status;

        if (statusCode === 401) {
            window.location.reload(); // User disconnected, reload to force re-login
            return;
        }

        let json_res = await res.json();
        if (!json_res.status) {
            let errors = json_res.errors;

            if (display_error && statusCode !== 403) {
                if (!errors || !Array.isArray(errors)) {
                    Notify.create({
                        type: "negative",
                        message: "Unkown server error. Please contact support."
                    });
                } else {
                    for (let error_message of errors) {
                        Notify.create({
                            type: "negative",
                            message: error_message
                        });
                    }
                }
            }

            throw new ApiError(errors, statusCode);
        }

        if (json_only) {
            return json_res;
        }

        await Api.look_for_model(json_res.relateds);
        for (let [field_name, field_value] of Object.entries(json_res)) {
            if (field_name !== 'relateds'){
                json_res[field_name] = await Api.look_for_model(field_value);
            }
        }


        if (json_res.hasOwnProperty("data")) {
            json_res = json_res.data;
        }
        return json_res;
    }

    static read () {
        return Api.filter(...arguments, { read: true });
    }

    static filter (
        model_constructor,
        filters = [],
        relateds = false,
        {
            read = false,
            fields_preview = false,
            limit = -1,
            start = 0,
            order_by = undefined
        } = {}) {

        if (typeof filters === "string") {
            filters = [ new CrudFilter("uuid", "eq", filters) ];
        }

        if (!Array.isArray(filters)) {
            filters = [filters];
        }

        let action = (read ? "read" : "filter");
        let data = {
            filters,
            limit,
            start,
            order_by,
            relateds
        };

        if (Array.isArray(fields_preview)) {
            action = "preview";
            data.fields = fields_preview;
        }

        return Api.post({
            handler: "api.crud",
            args: {
                action,
                model: model_constructor.api_name,
                data
            }
        });
    }

    static read_all (model_constructor, relateds) {
        return Api.filter(model_constructor, [], relateds);
    }

    static preview (model_constructor, fields_preview, filters, relateds, extra) {
        return Api.filter(model_constructor, filters, relateds, {
            fields_preview,
            ...extra
        });
    }

    static async look_for_model (obj) {
        if (!obj) {
            return obj;
        }

        if (typeof obj === "object" && !Api.is_model(obj)) {
            for (let [key, val] of Object.entries(obj)) {
                obj[key] = await Api.look_for_model(val);
            }
        } else if (Array.isArray(obj)) {
            return Promise.all(obj.map(o => Api.look_for_model(o)));
        }

        return Api.resolve_model(obj);
    }

    static resolve_models(list) {
        return Promise.all(list.map(obj => Api.resolve_model(obj)));
    }

    static async resolve_model(data) {
        if (!data) {
            return data;
        }

        if (Array.isArray(data)) {
            return Api.resolve_models(data);
        }

        if (!Api.is_model(data)) {
            return data;
        }

        let model_name = data._model_name;
        let model_constructor = await get_model(model_name);

        if (!model_constructor) {
            throw new Error(`Unknown object received from API ${model_name}`);
        }

        let instance = model_constructor.update_or_create(data);

        return instance;
    }
}

