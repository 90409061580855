<template>
  <q-page class="flex flex-center">
    <q-card>
        <q-card-section>
            <div class="text-h6" style="display:flex; justify-content: center;">Bienvenue sur le site de Mandragore !</div>
            <div class="text-h6" style="display:flex; justify-content: center; margin-top: 40px;">Afin de vous connecter, vous devez avoir accès à notre serveur discord.</div>
            <div class="text-h6" style="display:flex; justify-content: center; text-align: center;">Si vous souhaitez le rejoindre, merci de contacter @kehyll<br>(ou le cas échéant @shannah_amara)</div>
            <div style="display:flex; justify-content: center; margin-top: 20px;">
              <q-btn color="primary" label="Connexion Discord" @click="redirectToDiscord"/>
            </div>
        </q-card-section>
    </q-card>
  </q-page>
</template>

<style>
</style>

<script>
export default {
  name: 'LoginForm',
  methods: {
    redirectToDiscord () {
        const client_id = "935240029293412375";
        const backend_uri = `https://${window.location.hostname}/discord/auth`;
        const scopes = [
            "identify",
            "email",
            "guilds",
            "guilds.members.read"
        ];
        const params = {
            client_id,
            redirect_uri: backend_uri,
            scope: scopes.join(" "),
            response_type: "code"
        };
        const redirect_url = new URL("https://discord.com/api/oauth2/authorize");
        let query = "";

        for (let [key, value] of Object.entries(params)) {
            query += `${key}=${encodeURIComponent(value)}&`;
        }
        redirect_url.search = query;
        window.location = redirect_url;
    }
  }
}
</script>
