<template>
    <q-card>
        <q-tabs v-model="selectedTab" dense>
            <q-tab name="card" label="Carte Bancaire" />
            <q-tab name="3_times" label="3 fois sans frais (SEPA)" v-if="authorize3Times" />
        </q-tabs>
        <q-separator/>
        <q-tab-panels v-model="selectedTab" animated keep-alive>
            <q-tab-panel name="card">
                <div class="sr-input sr-card-element" id="card-element"></div>
            </q-tab-panel>
            <q-tab-panel name="3_times" v-if="authorize3Times">
                <div class="bg-deep-orange text-white q-pa-sm">
                    <q-icon name="warn" style="font-size: 1.5rem;" />
                    Cette fonctionnalité est en test. Veuillez privilégier le paiement par CB si possible, en cas de problème de prélévement vous devrez vous aquitter de la somme par virement sous 15 jours sinon votre place sera libérée.
                </div>

                Le paiement sera échelonné sur 3 mois, le mandat de prélévement signé expirera <strong>lors du dernier prélèvement</strong>.<br>
                <strong>Attention</strong> : merci de veiller à ce que votre compte soit bien approvisionné à la date de chaque échéance. Un paiement rejeté entraînera automatiquement l'annulation de la réservation. Les sommes déjà prélevées seront remboursées après déduction des frais de rejet (7.50 €). <br>
                Les dates de prélévement ci-dessous sont données à titre indicatif, un délai de 7 jours maximum peut être observé. <br>
                <div v-show="!mandate_signed">
                    <div class="row">
                        <div class="col-8">
                            <div id="mandate-acceptance">
                                <h5 class="q-mb-none">Mandat de prélèvement SEPA</h5>
                                <code class="text-justify">
                                    En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez : <br>
                                    (A) Mandragore et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et <br>
                                    (B) votre banque à débiter votre compte conformément à ces instructions.<br>
                                    Vous avez, entre autres, le droit de vous faire rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque.<br>
                                    La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité.<br>
                                    Vos droits sont expliqués dans une déclaration disponible auprès de votre banque.<br>
                                    Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.<br>
                                    Le présent mandat expirera le <strong>{{ end_date }}</strong>.
                                </code>
                            </div>
                        </div>
                        <div class="col">
                            <h5 class="q-mb-none">Échéances</h5>
                            <q-list bordered separator>
                                <q-item v-for="charge in charges">
                                    <q-item-section>
                                        <q-item-label>{{ charge.amount }} €</q-item-label>
                                        <q-item-label caption> Le {{ charge.date }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </div>
                    </div>
                    <q-form>
                        <q-input v-model="user.first_name" type="text" label="Prénom" :rules="[val => !!val || 'Ce champ est obligatoire']"/>
                        <q-input v-model="user.last_name" type="text" label="Nom" :rules="[val => !!val || 'Ce champ est obligatoire']"/>
                        <q-input v-model="user.email" type="email" label="Adresse email" :rules="[val => !!val || 'Ce champ est obligatoire']"/>
                    </q-form>
                    <q-btn @click="createOrUpdateMandate" color="primary" label="Accepter le mandat de prélèvement SEPA" />
                </div>
                <div class="q-my-lg" v-show="mandate_signed">
                    <label for="iban-element">IBAN</label>
                    <div id="iban-element">
                        <!-- Inserted by Stripe -->
                    </div>
                </div>
                <q-separator/>
            </q-tab-panel>
        </q-tab-panels>
        <q-separator/>
        <q-card-section class="text-right">
            Total: <q-badge>{{ amount }}</q-badge> €
        </q-card-section>
        <q-inner-loading :showing="is_loading">
            <q-spinner-gears size="50px" color="primary" />
        </q-inner-loading>
    </q-card>
</template>

<script>
import { ref } from "vue";
import Api from "@/utils/api.js";
import moment from "moment";
import { mapState, useStore } from "vuex";

export default {
    name: 'StripePayment',
    props: {
        amount: Number,
        description: String,
        authorize3Times : {
            type: Boolean,
            default: false
        },
        deduceFees: {
            type: Boolean,
            default: false
        }
    },
    mounted () {
        this.display_stripe_element();
    },
    computed: {
        charges () {
            let arr = []; // List of charges { amount, date } 

            const N_CHARGES = 3
            let date = moment();
            for (let i = 0; i < N_CHARGES; i++) {
                let amount = Math.floor(this.amount / N_CHARGES);

                if (i === 0) {
                    amount += this.amount % N_CHARGES;
                }

                arr.push({
                    amount,
                    date: moment(date)
                });

                date.add(1, 'months');
            }

            return arr;
        }
    },
    async setup () {
        const store = useStore();

        await store.state.userChecked;
        
        return {
            end_date: moment().add(2, 'months'),
            user: store.state.user,
            selectedTab: ref("card")
        };
    },
    data () {
        return {
            stripe: undefined,
            card_element: false,
            sepa_element: false,
            mandate_signed : false,
            client_secret: '',
            is_loading: false,
            mandate: false
        };
    },
    methods: {
        display_stripe_element () {
            //this.stripe = Stripe("pk_test_uBsMmnq3dGPEC5afmCF1dscX");
            this.stripe = Stripe("pk_live_9LUCqZ44E7IZtq7x5glg43um");
            let elements = this.stripe.elements({
                locale: "fr"
            });

            if (this.selectedTab === "card") {
                let card = elements.create("card");
                card.mount("#card-element");
                this.card_element = card;
            } else if (this.selectedTab === "3_times" && this.mandate_signed) {
                let iban = elements.create("iban", {
                    supportedCountries: ['SEPA'],
                    placeholderCountry: "FR"
                });
                iban.mount("#iban-element");
                this.sepa_element = iban;
            }
        },
        async pay () {
            if (this.selectedTab == "3_times") {
                return await this.pay_3_times();
            } else {
                return await this.pay_card();
            }
        },
        async createOrUpdateMandate () {
            this.is_loading = true;
            try {
                let { client_secret, mandate } = await Api.post({
                    handler: "user.create_or_update_sepa_mandate"
                });
                this.client_secret = client_secret;
                this.mandate = mandate;
                this.mandate_signed = this.client_secret !== '';
                this.display_stripe_element();
            } finally {
                this.is_loading = false;
            }
        },
        async pay_3_times () {
            if (!this.mandate_signed && this.client_secret === '') {
                this.$q.notify({
                    type: "negative",
                    message: "Veuilez accepter le mandat de prélévement SEPA."
                });
                return;
            }

            if (this.user.first_name === "" || this.user.last_name === "" || this.user.email === "") {
                this.$q.notify({
                    type: "negative",
                    message: "Veuilez remplir les champs Prénom, Nom et E-Mail"
                });
                return;
            }
            let res;
            this.is_loading = true;
            try {
                let resp = await this.stripe.confirmSepaDebitSetup(this.client_secret, {
                    payment_method: {
                        sepa_debit: this.sepa_element,
                        billing_details: {
                            name: this.user.first_name + " " + this.user.last_name,
                            email: this.user.email
                        }
                    }
                });

                if (resp.error) {
                    this.$q.notify({
                        type: "negative",
                        message: resp.error.message
                    });
                    this.is_loading = false;
                    return;
                }

                res = await Api.post({
                    handler: "user.pay",
                    args: {
                        mandate: this.mandate,
                        amount: this.amount,
                        description: this.description,
                        deduce_fees: this.deduceFees
                    }
                });
                
                res = { mandate: res };
                this.is_loading = false;
                
            } finally {
                this.is_loading = false;
            }
            return res;
        },
        async pay_card () {
            let { paymentMethod } = await this.stripe.createPaymentMethod({
                type: "card",
                card: this.card_element
            });
            // debugger;
            let res = await Api.post({
                handler: "user.pay",
                args: {
                    payment_method_id: paymentMethod.id,
                    amount: this.amount,
                    description: this.description,
                    deduce_fees: this.deduceFees
                }
            });

            if (res.requires_action) {
                // Handle 3D Secure
                const { error: errorAction, paymentIntent } = await this.stripe.handleCardAction(res.payment_intent_client_secret);

                if (errorAction) {
                    this.$q.notify({
                        type: "negative",
                        message: errorAction.message
                    });
                    throw new Error(errorAction.message);
                } else {
                    // Retry payment after 3DSecure success
                    res = await Api.post({
                        handler: "user.pay",
                        args: {
                            payment_intent_id: paymentIntent.id,
                            amount: this.amount,
                            description: this.description
                        }
                    });
                }
            }

            return {
                payment: res
            };
        }
    }
}
</script>
