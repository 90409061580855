<template>
    <q-layout class="background" view="lHh Lpr lFf">
        <q-header class="bg-white">
            <q-toolbar style="padding-left: 30px;">

                <router-link to="/">
                    <img src="/img/mandragore_logo.png" style="height: 70px; width: 70px; margin-top: 5px;" :to="{name: 'Home'}" />
                </router-link>
                <router-link to="/">
                    <img src="/img/mandragore_text_green.png" style="height: 35px;"  />
                </router-link>

                <q-space />

                <div style="display: flex;">
                    <q-btn label="News" :to="{name: 'Home'}" class="hover" color="dark" size="18px" flat no-caps stretch />
                    <q-separator color="mandragore-grey" vertical/>
                    <q-btn-dropdown label="L'association" class="hover" color="dark" size="18px" flat no-caps>
                        <q-list>
                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="Qui sommes-nous ?" :to="{name: 'Qui-sommes-nous'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="Le fonctionnement" :to="{name: 'Fonctionnement'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="La charte" :to="{name: 'Charte'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="L'asso" :to="{name: 'Asso'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="Les mangroves" :to="{name: 'Mangroves'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="Les parrainages" :to="{name: 'Parrainages'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>
                        </q-list>
                    </q-btn-dropdown>
                    <q-separator color="mandragore-grey" vertical/>

                    <q-btn-dropdown label="Les IRLS" class="hover" color="dark" size="18px" flat no-caps>
                        <q-list>
                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="IRL 2024" :to="{name: 'Event2024'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="onItemClick">
                            <q-item-section>
                                <q-btn label="IRLs à venir" :to="{name: 'EventList'}" class="hover" color="dark" flat no-caps stretch />
                            </q-item-section>
                            </q-item>
                        </q-list>
                    </q-btn-dropdown>


                    <q-btn flat label="" :to="{name: 'EventList'}" class="hover" color="dark" no-caps stretch />
                </div>

                <q-space />
            
                <template v-if="user">
                    <div class="q-mr-md">Bienvenue, {{ user.username }} !</div>
                    <q-btn icon="account_circle" :to="{name: 'Subscription'}" color="dark" stretch flat>
                        <q-tooltip>Profil</q-tooltip>
                    </q-btn>
                    <q-btn icon="paid" :to="{name: 'Payments'}" color="dark" stretch flat>
                        <q-tooltip>Historique Paiements</q-tooltip>
                    </q-btn>
                    <q-separator dark vertical/>
                    <template v-if="user.is_admin">
                        <q-separator dark vertical/>
                        <q-btn icon="admin_panel_settings" :to="{name: 'UserAdmin'}" color="dark" stretch flat>
                            <q-tooltip>Administration</q-tooltip>
                        </q-btn>
                    </template>
                    <q-btn icon="logout" @click="logout" color="dark" stretch flat>
                        <q-tooltip>Déconnexion</q-tooltip>
                    </q-btn>
                </template>
                <div v-else>Merci de vous connecter</div>
            </q-toolbar>
        </q-header>

        <q-page-container>
            <router-view v-if="user"></router-view>
            <LoginForm v-else/>
        </q-page-container>

      <q-toolbar class="bg-white" style="display:flex; justify-content: center;">
            <span>Tous droits reservés ©</span>
      </q-toolbar>

    </q-layout>
</template>

<script>
import LoginForm from './components/LoginForm.vue'
import Api from "@/utils/api.js";
import User from "@/models/user/user.js";
import { mapState } from "vuex";
import Role from "@/models/user/role.js";

export default {
    name: 'LayoutDefault',
    computed: mapState(["user"]),
    components: {
        LoginForm
    },
    async beforeMount () {
        this.$q.loading.show();
        try {
            const data = await Api.post({
                handler: "user.me"
            });

            const role_store = Role.get_store();
            for (let [role_name, permissions_list] of Object.entries(data.permissions)) {
                let role = role_store.get({
                    name: role_name
                });
                role.permissions = permissions_list;
            }

            this.$store.commit("connect", data.user);
        } catch (e) {
            this.$store.commit("confirm_check_user");
        }
        this.$q.loading.hide();
    },
    methods: {
        async logout () {
            await Api.post({
                handler: "user.logout"
            });
            this.$store.commit("disconnect");
        }
    }
}
</script>
