import { uuid4, check_types } from "@/utils/utils.js";

class Field {
    check () {
        throw new Error("Unimplemented");
    }

    get name () {
        throw new Error("Unimplemented");
    }

    get is_foreign_key () {
        return false;
    }

    get is_many_to_many () {
        return false;
    }

    get is_virtual () {
        return false;
    }

    get is_json () {
        return false;
    }

    get is_any () {
        return false;
    }

    toString () {
        return this.name;
    }
}

export function ForeignKey (foreign_class) {
    class _ForeignKey_ extends Field {
        constructor (foreign_class) {
            super();
            this.foreign_class = foreign_class;
        }

        check (value) {
            return (value instanceof this.foreign_class);
        }

        get is_foreign_key () {
            return true;
        }

        get name () {
            return "ForeignKey";
        }

        static get name () {
            return foreign_class.name;
        }
    }

    return new _ForeignKey_(foreign_class);
}

export function ManyToMany (foreign_class, foreign_name, fetch_fields) {
    class _ManyToMany_ extends Field {
        constructor (foreign_class) {
            super();
            if (!foreign_name) {
                foreign_name = foreign_class.name.toLowerCase() + "s";
            }

            this.foreign_name = foreign_name;
            this.foreign_class = foreign_class;
            this.fetch_fields = fetch_fields;
        }

        check (values) {
            for (let val of values) {
                if (!(val instanceof this.foreign_class)) {
                    return false;
                }
            }

            return true;
        }

        get name () {
            return "ManyToMany";
        }

        get is_many_to_many () {
            return true;
        }

        static get name () {
            return foreign_class.name;
        }
    }

    return new _ManyToMany_(foreign_class);
}

export function Virtual (el) {
    class _VirtualField_ extends Field {
        constructor (el) {
            super();
            this.el_constructor = el;
        }

        check () {
            return true;
        }

        get name () {
            return "Virtual";
        }

        get is_virtual () {
            return true;
        }

        static get name () {
            return "Virtual";
        }
    }

    return new _VirtualField_(el);
}

export function JSONField () {
    class _JSONField_ extends Field {
        check (val) {
            if (!val) {
                return true;
            }

            if (typeof val === "object") {
                return true;
            }

            try {
                JSON.parse(val);
                return true;
            } catch (e) {
                return false;
            }
        }

        convert (val) {
            if (val && typeof val === "string") {
                return JSON.parse(val);
            }

            return val;
        }

        get name () {
            return "JSONField";
        }

        get is_json () {
            return true;
        }

        static get name () {
            return "JSONField";
        }
    }


    return new _JSONField_();
}

export function ListField (SubType) {
    class _ListField_ extends Field {
        check (vals) {
            if (typeof vals === "object") {
                return true;
            }
            if (!Array.isArray(vals)) {
                return false;
            }
            let valid = true;

            for (let val of vals) {
                valid &= check_types(val, SubType);
            }

            return valid;
        }

        get name () {
            return "ListField"
        }

        static get name () {
            return "ListField";
        }
    }

    return new _ListField_(SubType);
}

export function AnyField () {
    class _AnyField_ extends Field {
        check () {
            return true;
        }

        get is_any () {
            return true;
        }

        get name () {
            return "AnyField";
        }


        static get name () {
            return "AnyField";
        }
    }

    return new _AnyField_();
}
