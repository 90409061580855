import { ManyToMany } from "@/models/fields.js";
import BaseModel from "@/models/base-model.js";

export default class Role extends BaseModel {
    has_permission (name) {
        if (!this.permissions) {
            return false;
        }

        return this.permissions.includes(name);
    }

    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "name": {
                    type: String,
                    in_list: true,
                    anchor: true
                },
                "priority": {
                    type: Number
                }
                // permissions => array of strings added on user.me requests
                //
            }
        );
    }

    static get api_name () {
        return "user.role";
    }
}

