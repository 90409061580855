import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store.js";
import Home from '../views/Home.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import("../views/news/Index.vue"),
    },
    {
        path: '/association',
        children: [
            {
                path: 'qui-sommes-nous',
                name: 'Qui-sommes-nous',
                component: () => import("../views/association/Qui-sommes-nous.vue")
            },
            {
                path: 'fonctionnement',
                name: 'Fonctionnement',
                component: () => import("../views/association/Fonctionnement.vue")
            },
            {
                path: 'charte',
                name: 'Charte',
                component: () => import("../views/association/Charte.vue")
            },
            {
                path: 'asso',
                name: 'Asso',
                component: () => import("../views/association/Asso.vue")
            },
            {
                path: 'mangroves',
                name: 'Mangroves',
                component: () => import("../views/association/Mangroves.vue")
            },
            {
                path: 'parrainages',
                name: 'Parrainages',
                component: () => import("../views/association/Parrainages.vue")
            }
        ]
    },
    {
        path: '/shop',
        name: 'Shop',
        component: () => import("../views/shop/Index.vue"),
        beforeEnter: (to, from, next) => {
            store.state.userChecked.then(() => {
                if (!store.state.user || !store.state.user.has_permission('handler:shop__access')) {
                    return next({ name: "Home" });
                }

                next();
            });
        }
    },
    {
        path: '/user',
        name: 'User',
        component: () => import("../views/user/Home.vue"),
        children: [{
            path: 'subscription',
            name: 'Subscription',
            component: () => import("../views/user/Subscription.vue")
        }, {
            path: 'payments',
            name: 'Payments',
            component: () => import("../views/user/Payments.vue")
        }]
    },
    {
        path: '/admin',
        name: 'Admin',
        component: function () {
            return import(/* webpackChunkName: "admin" */ '../views/admin/Index.vue')
        },
        children: [{
            name: "UserAdmin",
            path: "users",
            component: () => import("../views/admin/User.vue")
        }, {
            path: "",
            redirect: "/admin/users"
        }, {
            name: "PaymentAdmin",
            path: "payments",
            component: () => import("../views/admin/Payments.vue")
        }],
        beforeEnter: (to, from, next) => {
            store.state.userChecked.then(() => {
                if (!store.state.user || !store.state.user.is_admin) {
                    return next({ name: "Home" });
                }

                next();
            });
        }
    },
    {
        path: '/event',
        name: "Event",
        component: () => import("../views/event/Index.vue"),
        children: [{
            path: "",
            name: "EventList",
            component: () => import("../views/event/List.vue")
        }, {
            path: ":event_id",
            name: "EventDetail",
            component: () => import("../views/event/Detail.vue")
        }]
    },
    {
        path: '/passedevent',
        children: [{
            path: '',
            name: 'PassedEventList',
            component: () => import("../views/passedevent/List.vue")
        }, {
                path: '2024',
                name: 'Event2024',
                component: () => import("../views/passedevent/2024.vue")
        }]
    }
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 }; // Scrolls to the top smoothly
    },
});

export default router;
