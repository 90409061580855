import { reactive } from "vue";
import EventEmitter from "@/utils/event-emitter.js";
import { check_types, uuid4 } from "@/utils/utils.js";
import Api, { CrudFilter } from "@/utils/api.js";
import Store from "@/utils/store.js";
import moment from "moment";

const DEFAULT_FIELD_SETTINGS = {
    type: String,
    auto_fetch: false,
    label: false, // renames the key in the view
    writable: false,
    rewritable: true,
    modify_only: false,
    required: true,
    enumerable: true,
    in_list: false,
    anchor: false,
    anchor_type: String,
    ordered: false,
    editable: true,
    filter: false,
    filter_config: false,
    base_only: false,
    fields: false,
    requires: undefined,
    is_widget: false,
    separator: false,
    query: false,
    custom_elements: {
        display: false,
        input: null
    }
};

function _base_getter (field_name, field_type) {
    let pk = this.__data[field_name];
    let val = pk;

    if (field_type && (field_type.is_foreign_key || field_type.is_many_to_many)) {
        const store = field_type.foreign_class.get_store();

        if (field_type.is_foreign_key) {
           val  = store.find_by_id(pk);

            if (!val && pk) {
                val = new field_type.foreign_class({
                    data: {
                        [field_type.foreign_class.index_field]: pk
                    }
                });
            }
        } else {
            val = [...pk].map(pk => store.find_by_id(pk)).filter(obj => obj !== undefined);
        }
    }

    return val;
}

function _base_setter (field_name, field_type, field_value) {
    if (field_type.is_foreign_key && field_value instanceof BaseModel) {
        field_value = field_value[field_value.index_field];
    }

    if (typeof field_type.convert === "function") {
        field_value = field_type.convert(field_value);
    }

    if (field_type === String || field_type === Number) {
        field_value = field_type(field_value);
    }

    this.__data[field_name] = field_value;
    this.fire("update");
}

function _pk_setter (field_name, field_type, field_value) {
    const store = this.constructor.get_store();
    const previous_pk = this[field_name];
    this.__data[field_name] = field_value;
    store.change_index(previous_pk, this);
}

function _prevent_set (field_name, field_type, field_value) {
    if (this.__data[field_name] === field_value) {
        return;
    }

    throw new Error(`${field_name} is not a writable field`);
}

export default class BaseModel extends EventEmitter {
    constructor ({
        data = {}
    }) {
        super();

        const description = this.constructor.describe();

        this.phantom = false;
        this.has_data = false;
        this.fields = [];
        this.__data = {}; // Raw data
        this.__views = {}; // View object for each field
        this.__fields_loaded = {}; // Loaded state for each field (If FK has been fetched for example)

        if (data[this.index_field]) {
            data[this.index_field] = data[this.constructor.index_field];
        }

        if (description.hasOwnProperty(this.index_field)) {
            let pk = data[this.index_field];

            if (!pk) {
                this.phantom = true;
                pk = uuid4();
            }

            this.add_accessors(this.index_field, description[this.index_field]);
            this.__data[this.index_field] = pk;
            this.push_in_store();
        }

        for (let [field_name, field_settings] of Object.entries(description)) {
            let val = data[field_name];

            if (field_name === this.index_field) {
                continue;
            }

            this.fields.push(field_name);
            this.add_accessors(field_name, field_settings);

            field_settings = BaseModel.with_default_settings(field_settings);
            let field_type = field_settings.type;

            if (field_type.is_many_to_many) {
                val = data[field_name + "_pks"];

                if (!val) {
                    this.__data[field_name] = reactive([]);
                }
            }

            if (val !== undefined) {
                this.define_value(field_name, field_settings, val);
            }
        }

        this.finish_construct();
    }

    format (format) {
        return format.replace(/({[a-z_]+})/g, (m, p) => {
            return this.get_value_by_name(p.slice(1, -1));
        });
    }

    get pk () {
        return this[this.index_field];
    }

    get index_field () {
        return this.constructor.index_field;
    }

    static get index_field () {
        return "uuid";
    }

    append_value (field_name, field_settings, value) {
        let field_type = field_settings.type;

        if (!(field_name in this.__data)) {
            this.define_value(...arguments);
            return;
        }

        if (field_type.is_many_to_many) {
            this.__data[field_name].add(value);
            return;
        }

        if (field_type.is_foreign_key && this.__data[field_name]) {
            this.__data[field_name] = value;
            return;
        }

        this.__data[field_name] = value;
    }

    define_value (field_name, field_settings, value) {
        let field_type = field_settings.type;

        this.has_data = true;

        if (field_name === this.index_field) {
            this[this.index_field] = value;
            return;
        }

        if (!check_types(value, field_type)) {
            throw new TypeError(`Bad type for field ${field_name}.\n
                Expected ${field_type}, Received ${JSON.stringify(value)}\n
                Class ${this.constructor.name}`
            );
        }

        if (field_type === Date) {
            if (typeof value === "number") {
                value = moment.unix(value);
            } else {
                value = moment(value);
            }
        }

        if (field_type.is_foreign_key) {
            if (!value) {
                this.__data[field_name] = null;
                return;
            }

            if (typeof value === "object") {
                value = value.pk;
            }

            let store = field_settings.type.foreign_class.get_store();
            this.__fields_loaded[field_name] = store.watch(value);
        } else {
            this.__fields_loaded[field_name] = Promise.resolve(value);
        }

        this.__data[field_name] = value;
    }

    add_accessors (field_name, field_settings) {
        if (field_name in this) {
            throw new Error(`${field_name} clashes with another property in ${this.constructor.name}.`);
        }

        let setter_function = _base_setter;

        if (field_name === this.index_field) {
            setter_function = _pk_setter;
        } else if (!field_settings.writable && !field_settings.query) {
            setter_function = _prevent_set;
        }

        Object.defineProperty(this, field_name, {
            get: _base_getter.bind(this, field_name, field_settings.type),
            set: setter_function.bind(this, field_name, field_settings.type)
        });
    }

    update_values (data) {
        const description = this.constructor.describe();

        for (let [field_name, value] of Object.entries(data)) {
            field_name = field_name.replace("_pks", "");

            if (!(field_name in description)) {
                continue;
            }

            let field_settings = BaseModel.with_default_settings(description[field_name]);
            let field_type = field_settings.field_type;


            if (field_type && field_settings.field_type.is_many_to_many) {
                value = data[field_name + "_pks"];
            }


            this.define_value(field_name, field_settings, value);
        }

        this.constructor.get_store().check_watchers();

        // this.fire("update");
        return this;
    }

    static filter () {
        return Api.filter(this, ...arguments);
    }

    static async get () {
        const res = await Api.read(this, ...arguments);
        return res[0];
    }

    create (extra_fields = {}) {
        return Api.post({
            handler: `api.crud`,
            args: {
                action: "create",
                model: this.api_name,
                data: {
                    fields: {...this.get_flattened_data(false, false), ...extra_fields }
                }
            },
            json_only: true
        }).then(json => {
            if (json.hasOwnProperty("data")) {
                json = json.data;
            }

            this.update_values(json);
            this.fire("update");

            this.phantom = false;
            return this;
        });
    }

    read () {
        if (this._read_pm) {
            return this._read_pm;
        }

        // Cache promise to avoid sending multiple reqs for the same object
        this._read_pm = this.constructor.read(this[this.constructor.index_field], ...arguments).then(obj => {
            this.fire("update");
            this._read_pm = false;
            return obj;
        });

        return this._read_pm;
    }

    static read (uuid, relateds) {
        return Api.read(this, uuid, relateds);
    }

    static read_all () {
        return Api.read_all(this, ...arguments);
    }

    static preview () {
        return Api.preview(this, ...arguments);
    }

    update (extra_fields = {}) {
        if (this.phantom) {
            return this.create();
        }

        return Api.post({
            handler: `api.crud`,
            args: {
                action: "update",
                model: this.api_name,
                data: {
                    fields: {...this.get_flattened_data(), ...extra_fields }
                }
            }
        }).then(res => {
            this.fire("update");
            return this;
        });
    }

    remove () {
        return Api.post({
            handler: 'api.crud',
            args: {
                action: "delete",
                model: this.api_name,
                data: {
                    filters: [{
                        field: "uuid",
                        operator: "eq",
                        value: this.uuid
                    }]
                }
            }
        }).then(res => {
            this.constructor.get_store().remove(this);
            return res;
        });
    }

    fetch_relations (field_name, relateds = false, preview = false) {
        const description = this.constructor.describe();
        const field = description[field_name];

        if (!field) {
            throw new Error(`Cannot find field ${field_name}.`);
        }

        let pks = this.__data[field_name];

        if (!Array.isArray(pks)) {
            pks = [pks];
        }

        if (!pks.length) {
            return Promise.resolve([]);
        }

        if (preview && preview.length) {
            return field.type.foreign_class.preview(preview, [
                new CrudFilter("pk", "in", pks)
            ], relateds);
        }

        return field.type.foreign_class.filter([
            new CrudFilter("pk", "in", pks)
        ], relateds);
    }

    remove_from_store () {
        this.constructor.get_store().remove(this);
    }

    push_in_store () {
        let constructor = this.constructor;
        while (true) {
            if (constructor === BaseModel) {
                break;
            }

            constructor.get_store().append(this, true);
            constructor = constructor.__proto__; // Append obj in parents stores
        }
    }

    finish_construct () {
        // Broadcast the event when the object is fully created
        this.constructor.get_store().fire_append(this);
    }

    get api_name () {
        return this.constructor.api_name;
    }

    set_view (field_name, view) {
        this.__views[field_name] = view;
    }

    get_view (field_name) {
        if (!this.__views.hasOwnProperty(field_name)) {
            throw new Error(`${this.constructor.name} has no view ${field_name}`);
        }
        
        let view = this.__views[field_name];

        if (view.constructor.name === "FutureValue") {
            return view.view;
        }


        return view;
    }

    get_url () {
        return this.constructor.make_url(this[this.index_field]);
    }

    static make_url(pk) {
        let path = this.anchor_path;

        if (!path.endsWith("/")) {
            path += "/";
        }

        return path + pk;
    }

    get_flattened_data (no_pk=false, writable_only=true) {
        let fields;

        if (writable_only) {
            fields = this.get_fields_with_prop("writable", true);
        } else {
            fields = this.get_fields_with_prop();
        }

        let data = {};

        for (let [key, config] of Object.entries(fields)) {
            let value = this.__data[key];

            if (config.type.is_many_to_many) {
                if (typeof value === "string") {
                    value = value.split("\n");
                }

                data[key] = [...value].map(val => {
                    if (val.uuid && !config.custom_elements.input) {
                        return val.uuid;
                    }

                    if (val instanceof BaseModel) {
                        return val.get_flattened_data();
                    }

                    return val.data || val.__data || val;
                });
                continue;
            }

            if (config.type.is_foreign_key && value) {
                data[key] = value;
                continue;
            }

            data[key] = value;
        }

        if (!this.phantom && !no_pk) {
            data[this.index_field] = this.pk;
        }

        return data;
    }

    serialize () {
        return {
            pk: this.pk,
            _model_name: this.api_name,
            ...this.__data
        };
    }

    get_value_by_name (str) {
        let path = str.split("__");
        
        let obj = this;
        for (let part of path) {
            if (obj === null || typeof obj !== "object") {
                return obj;
            }

            obj = obj[part];
        }

        return obj;
    }

    static get_field_by_name (str) {
        let path = str.split("__");
        let field_name = path[0];
        let description = this.describe();
        let field = description[field_name];

        if (!field) {
            throw new Error(`Cannot find field ${field_name} in ${this}.`);
        }

        if (field.is_many_to_many || field.is_foreign_key) {
            return field.foreign_class.get_field_by_name(path.slice(1));
        }

        return field;
    }

    static update_or_create (data) {
        if (Array.isArray(data)) {
            return data.map(obj => this.update_or_create(obj));
        }

        let instance = this.get_store().find_by_id(data.uuid);

        if (instance) {
            instance.update_values(data);
        } else {
            instance = new this({data});
        }

        return this.find_in_memory(data.uuid); // Trick to have proxy object from Store
    }

    static get_store () {
        return Store.get_or_create(this);
    }

    static find_in_memory (uuid) {
        return this.get_store().find_by_id(uuid);
    }

    static with_default_settings (settings) {
        if (typeof settings !== "object") {
            if (typeof settings === "function") {
                settings = Object.assign({}, DEFAULT_FIELD_SETTINGS, {
                    type: settings
                });
            }
        } else {
            settings = Object.assign({}, DEFAULT_FIELD_SETTINGS, settings);
        }

        return settings;
    }

    get_fields_with_prop () {
        return this.constructor.get_fields_with_prop(...arguments);
    }

    static get_fields_with_prop (prop, val) {
        let fields = {};

        for (let [field_name, field_settings] of Object.entries(this.describe())) {
            let [rel, path] = field_name.split("__");

            if (rel && path) {
                field_settings.requires = rel;
            }

            field_settings = BaseModel.with_default_settings(field_settings);

            if (field_settings[prop] === val) {
                fields[field_name] = field_settings;
            }
        }

        return fields;
    }

    static auto_update () {
        if (this.update_loop) {
            return;
        }

        this.update_loop = true;
        this.read_all(false).then(() => {
            this.update_loop = false;
            setTimeout(this.auto_update.bind(this), 10000);
        });
    }

    describe () {
        return this.constructor.describe();
    }

    static describe () {
        return {
            "uuid": {
                type: String,
                writable: false,
                enumerable: false
            },
            "created_at": {
                type: Date
            },
            "updated_at": {
                type: Date
            }
        };
    }

    get _label () {
        let label_field = Object.keys(this.constructor.get_fields_with_prop("anchor", true))[0];
        return this[label_field];
    }

    toString () {
        return this._label;
    }
}
