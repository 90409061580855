import { ForeignKey, ManyToMany } from "@/models/fields.js";
import BaseModel from "@/models/base-model.js";
import User from "@/models/user/user.js";
import EventPrice from "@/models/event/eventprice.js";
import EventExtra from "@/models/event/eventextra.js";

export default class EventModel extends BaseModel {
    get start_time_formatted () {
        return this.start_time.format("DD/MM/YYYY");
    }

    get end_time_formatted () {
        return this.end_time.format("DD/MM/YYYY");
    }

    get remaining_slots () {
        return this.attendees_limit -  this.attendees_number;
    }

    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "prices": {
                    type: ManyToMany(EventPrice),
                    writable: true
                },
                "extras": {
                    type: ManyToMany(EventExtra),
                    writable: true
                },
                "attendees": {
                    type: ManyToMany(User)
                },
                "start_time": {
                    type: Date,
                    writable: true
                },
                "end_time": {
                    type: Date,
                    writable: true
                },
                "address": {
                    type: String,
                    writable: true
                },
                "description": {
                    type: String,
                    writable: true
                },
                "title": {
                    type: String,
                    writable: true
                },
                "attendees_limit": {
                    type: Number,
                    writable: true
                },
                "attendees_number": {
                    type: Number
                },
                "owner": {
                    type: ForeignKey(User),
                    writable: true
                },
                "pre_register_code": {
                    type: String,
                    writable: true
                },
                "registration_opened": {
                    type: Boolean,
                    writable: true
                },
                "is_archived": {
                    type: Boolean,
                    writable: true
                }
            }
        );
    }

    static get api_name () {
        return "event.event";
    }
}

